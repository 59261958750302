<template>
  <div
    class="component-event-item"
    :class="{ 'cursor-pointer': cursorPointer }"
  >
    <div class="flex">
      <div
        class="w-24 h-24 sm:w-28 sm:h-28 rounded-xl bg-cover bg-center relative"
        :style="{ backgroundImage: `url('${eventImage}')` }"
      >
        <RoundIcon
          v-if="activityIcon"
          :size="30"
          :border-size="0"
          :icon-class="activityIcon"
          class="absolute bottom-0 right-0"
        />
      </div>
      <div class="ml-4 text-sm overflow-ellipsis flex-1">
        <h2 v-if="showName" class="m-0 mb-1">
          {{ name }}
        </h2>
        <div class="mb-1.5">
          <RoundIcon :size="24" icon-class="fal fa-clock" class="mr-1" />
          <template v-if="previewEvent">
            <template v-if="endDate && recurringMapped">
              {{ date }} {{ startTime }}-{{ endTime }} ({{
                recurringMapped
              }}
              tot {{ endDate }})
            </template>
            <template v-else>{{ date }} {{ startTime }}-{{ endTime }}</template>
          </template>
          <template
            v-else-if="
              (date === null && (startTime === null || endTime === null)) ||
              eventIsFinished
            "
          >
            <em>afgelopen</em>
          </template>
          <template v-else-if="eventIsCancelled">Geannuleerd</template>
          <template v-else>{{ date }} {{ startTime }}-{{ endTime }}</template>
        </div>
        <div class="mb-1.5">
          <RoundIcon :size="24" icon-class="fal fa-building" class="mr-1" />
          <span>{{ location }}</span>
        </div>
        <div
          class="flex"
          :class="showName ? 'flex-row content-end' : 'flex-column'"
        >
          <div class="mb-1.5 flex-1">
            <RoundIcon :size="24" icon-class="fal fa-euro-sign" class="mr-1" />
            <template v-if="cost > 0">{{ $n(cost, 'currency') }}</template>
            <template v-else>Gratis</template>
          </div>
          <div>
            <template v-if="previewEvent">
              <RoundIcon :size="24" icon-class="fal fa-users" class="mr-1" />
              max. {{ maximumParticipants }} deelnemers
            </template>

            <template v-else-if="isEnded">Afgelopen</template>

            <template v-else-if="eventIsFinished">
              <RoundIcon :size="24" icon-class="fal fa-users" class="mr-1" />
              {{ finishedNumberOfParticipants }}/{{ maximumParticipants }}
              <span>deelgenomen</span>
            </template>

            <template v-else-if="eventIsCancelled">
              <RoundIcon :size="24" icon-class="fal fa-users" class="mr-1" />
              0/{{ maximumParticipants }}
            </template>

            <template
              v-else-if="
                origin !== '/event-list-own' &&
                !date &&
                (!startTime || !endTime)
              "
            >
              <RoundIcon :size="24" icon-class="fal fa-users" class="mr-1" />
              {{ finishedNumberOfParticipants }}/{{ maximumParticipants }}
              <span>deelgenomen</span>
            </template>

            <template v-else-if="numberOfParticipants < maximumParticipants">
              <template v-if="showAttendance">
                <RoundIcon :size="24" icon-class="fal fa-users" class="mr-1" />
                {{ numberOfParticipants }}/{{ maximumParticipants }}
                <span v-if="!showName">Bezet</span>
              </template>
            </template>
            <template v-else>
              <RoundIcon
                :size="24"
                icon-class="fal fa-users"
                :is-active="true"
                class="mr-1"
              />
              <template v-if="numOfNotFullEvents">
                <span class="not-available">
                  Vol (nog wel plek op
                  {{ $tc('availability-days', numOfNotFullEvents) }})
                </span>
              </template>
              <template v-else>
                <span class="not-available">Vol</span>
              </template>
            </template>
            <template v-if="previewEvent && privateEvent">
              <div class="mt-2">
                <RoundIcon :size="24" icon-class="fal fa-lock" class="mr-1" />
                prive event
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix" />
    <button v-if="buttonText !== null" class="btn btn-green btn-block btn-sm">
      {{ buttonText }}
    </button>
    <div
      v-if="locationFull !== null && !eventIsCancelled"
      class="flex mt-4 text-sm items-center"
    >
      <div class="mr-2">
        <RoundIcon :size="24" icon-class="fal fa-map-marker-alt" />
      </div>
      <a
        v-if="locationLatLng !== null"
        :href="
          'https://www.google.com/maps/search/?api=1&query=' + locationLatLng
        "
        target="_blank"
        class="location-full-text"
      >
        {{ locationFull }}
      </a>
      <span v-else class="location-full-text">{{ locationFull }}</span>
    </div>
    <hr class="my-3.5 border-gray-300" />
  </div>
</template>

<script>
import RoundIcon from './RoundIcon'
import { DateTime } from 'luxon'
import data from '@/utils/data'

export default {
  name: 'EventItem',
  components: { RoundIcon },
  props: {
    name: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: false,
      default: ''
    },
    startTime: {
      type: String,
      required: false,
      default: ''
    },
    endTime: {
      type: String,
      required: false,
      default: ''
    },
    location: {
      type: String,
      required: true
    },
    cost: {
      type: String,
      required: true
    },
    maximumParticipants: {
      type: Number,
      required: true
    },
    numberOfParticipants: {
      type: Number,
      required: false,
      default: 0
    },
    activityIcon: {
      type: String,
      required: false,
      default: ''
    },
    eventImage: {
      type: String,
      required: false,
      default: ''
    },
    locationFull: {
      type: String,
      required: false,
      default: null
    },
    locationLatLng: {
      type: String,
      required: false,
      default: null
    },
    showName: {
      type: Boolean,
      required: false,
      default: true
    },
    buttonText: {
      type: String,
      required: false,
      default: null
    },
    cursorPointer: {
      type: Boolean,
      required: false,
      default: false
    },
    numOfNotFullEvents: {
      type: Number,
      required: false,
      default: 0
    },
    finishedNumberOfParticipants: {
      type: Number,
      required: false,
      default: 0
    },
    origin: {
      type: String,
      required: false,
      default: ''
    },
    dateId: {
      type: Number,
      required: false,
      default: null
    },
    previewEvent: {
      type: Boolean,
      required: false,
      default: false
    },
    endDate: {
      type: String,
      required: false,
      default: ''
    },
    recurring: {
      type: String,
      required: false,
      default: ''
    },
    isEnded: {
      type: Boolean,
      required: false,
      default: false
    },
    privateEvent: {
      type: Boolean,
      required: false,
      default: false
    },
    eventIsCancelled: {
      type: Boolean,
      required: false,
      default: false
    },
    showAttendance: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    recurringMapped: function () {
      return data.formatRecurring(this.recurring)
    },
    eventIsFinished: function () {
      return DateTime.local() > DateTime.fromISO(this.endDate)
    }
  }
}
</script>
