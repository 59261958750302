var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-event-item",class:{ 'cursor-pointer': _vm.cursorPointer }},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-24 h-24 sm:w-28 sm:h-28 rounded-xl bg-cover bg-center relative",style:({ backgroundImage: ("url('" + _vm.eventImage + "')") })},[(_vm.activityIcon)?_c('RoundIcon',{staticClass:"absolute bottom-0 right-0",attrs:{"size":30,"border-size":0,"icon-class":_vm.activityIcon}}):_vm._e()],1),_c('div',{staticClass:"ml-4 text-sm overflow-ellipsis flex-1"},[(_vm.showName)?_c('h2',{staticClass:"m-0 mb-1"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),_c('div',{staticClass:"mb-1.5"},[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-clock"}}),(_vm.previewEvent)?[(_vm.endDate && _vm.recurringMapped)?[_vm._v(" "+_vm._s(_vm.date)+" "+_vm._s(_vm.startTime)+"-"+_vm._s(_vm.endTime)+" ("+_vm._s(_vm.recurringMapped)+" tot "+_vm._s(_vm.endDate)+") ")]:[_vm._v(_vm._s(_vm.date)+" "+_vm._s(_vm.startTime)+"-"+_vm._s(_vm.endTime))]]:(
            (_vm.date === null && (_vm.startTime === null || _vm.endTime === null)) ||
            _vm.eventIsFinished
          )?[_c('em',[_vm._v("afgelopen")])]:(_vm.eventIsCancelled)?[_vm._v("Geannuleerd")]:[_vm._v(_vm._s(_vm.date)+" "+_vm._s(_vm.startTime)+"-"+_vm._s(_vm.endTime))]],2),_c('div',{staticClass:"mb-1.5"},[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-building"}}),_c('span',[_vm._v(_vm._s(_vm.location))])],1),_c('div',{staticClass:"flex",class:_vm.showName ? 'flex-row content-end' : 'flex-column'},[_c('div',{staticClass:"mb-1.5 flex-1"},[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-euro-sign"}}),(_vm.cost > 0)?[_vm._v(_vm._s(_vm.$n(_vm.cost, 'currency')))]:[_vm._v("Gratis")]],2),_c('div',[(_vm.previewEvent)?[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-users"}}),_vm._v(" max. "+_vm._s(_vm.maximumParticipants)+" deelnemers ")]:(_vm.isEnded)?[_vm._v("Afgelopen")]:(_vm.eventIsFinished)?[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-users"}}),_vm._v(" "+_vm._s(_vm.finishedNumberOfParticipants)+"/"+_vm._s(_vm.maximumParticipants)+" "),_c('span',[_vm._v("deelgenomen")])]:(_vm.eventIsCancelled)?[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-users"}}),_vm._v(" 0/"+_vm._s(_vm.maximumParticipants)+" ")]:(
              _vm.origin !== '/event-list-own' &&
              !_vm.date &&
              (!_vm.startTime || !_vm.endTime)
            )?[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-users"}}),_vm._v(" "+_vm._s(_vm.finishedNumberOfParticipants)+"/"+_vm._s(_vm.maximumParticipants)+" "),_c('span',[_vm._v("deelgenomen")])]:(_vm.numberOfParticipants < _vm.maximumParticipants)?[(_vm.showAttendance)?[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-users"}}),_vm._v(" "+_vm._s(_vm.numberOfParticipants)+"/"+_vm._s(_vm.maximumParticipants)+" "),(!_vm.showName)?_c('span',[_vm._v("Bezet")]):_vm._e()]:_vm._e()]:[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-users","is-active":true}}),(_vm.numOfNotFullEvents)?[_c('span',{staticClass:"not-available"},[_vm._v(" Vol (nog wel plek op "+_vm._s(_vm.$tc('availability-days', _vm.numOfNotFullEvents))+") ")])]:[_c('span',{staticClass:"not-available"},[_vm._v("Vol")])]],(_vm.previewEvent && _vm.privateEvent)?[_c('div',{staticClass:"mt-2"},[_c('RoundIcon',{staticClass:"mr-1",attrs:{"size":24,"icon-class":"fal fa-lock"}}),_vm._v(" prive event ")],1)]:_vm._e()],2)])])]),_c('div',{staticClass:"clearfix"}),(_vm.buttonText !== null)?_c('button',{staticClass:"btn btn-green btn-block btn-sm"},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e(),(_vm.locationFull !== null && !_vm.eventIsCancelled)?_c('div',{staticClass:"flex mt-4 text-sm items-center"},[_c('div',{staticClass:"mr-2"},[_c('RoundIcon',{attrs:{"size":24,"icon-class":"fal fa-map-marker-alt"}})],1),(_vm.locationLatLng !== null)?_c('a',{staticClass:"location-full-text",attrs:{"href":'https://www.google.com/maps/search/?api=1&query=' + _vm.locationLatLng,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.locationFull)+" ")]):_c('span',{staticClass:"location-full-text"},[_vm._v(_vm._s(_vm.locationFull))])]):_vm._e(),_c('hr',{staticClass:"my-3.5 border-gray-300"})])}
var staticRenderFns = []

export { render, staticRenderFns }